import React from 'react';

import {Component} from 'react';

import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

// import { ReactTypeformEmbed } from 'react-typeform-embed';


class Home extends Component {
  constructor(props) {
    super(props);
    this.openForm = this.openForm.bind(this);
    this.markdown = props.data.allMarkdownRemark.edges;
    this.json = props.data.allFeaturesJson.edges;
  }



  openForm() {
    this.typeformEmbed.typeform.open();
  }

  render() {
    return (
          <Layout bodyClass="page-home">
              <SEO title="Home" />
              <Helmet>
                <meta
                  name="description"
                  content="We are a research institute for education and reproducibility of research in machine intelligence."
                />
              </Helmet>
              <div className="intro pb-4">
                <div className="container">
                  <h1>CongruentAI</h1>
                  <p>
                Supporting independent research and taking on the problem of research reproducibility in machine intelligence.


                  </p>
                </div>
              </div>



              <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <h2 className="title-3 text-dark mb-4">Our Mission</h2>
                  </div>
                  {this.json.map(edge => (
                    <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                      <div className="feature">
                        {edge.node.image && (
                          <div className="feature-image">
                            <img src={withPrefix(edge.node.image)} />
                          </div>
                        )}
                        <h2 className="feature-title">{edge.node.title}</h2>
                        <div className="feature-content">{edge.node.description}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Layout>
    );
  }
}



export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;


export default Home;
